<template>
  <div>
    <v-app-bar dark fixed app :color="systemDetails.themecolor">
      <v-app-bar-nav-icon>
        <v-btn icon @click="$router.go(-1)"><v-icon :color="systemDetails.textcolor">mdi-arrow-left</v-icon></v-btn>
      </v-app-bar-nav-icon>
      <v-app-bar-title :style="`color: ${systemDetails.textcolor}`">{{ title }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn :color="$vuetify.theme.dark ? '' : systemDetails.textcolor" text @click="saveUpdateHandler" :loading="loader" class="justify-end">
        <!-- {{ ticketObj.id ? this.$t('update') : this.$t('save') }} -->
        <v-icon size="20">mdi-checkbox-marked-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <form-template :references.sync="formReferences" :model="ticketObj" ref="ticketReference">
      <template slot="extraDetails">
         <v-container class="grid-list-xl" style="padding:0px !important" v-if="modulesRelated.length > 0">
          <v-layout wrap class="pl-4 mb-1">
          <!--Module related-->
          <template v-for="(item, index) in modulesRelated">
            <v-flex lg3 :key="index" class="pa-2 pl-4 pr-7">
              <v-autocomplete  outlined dense :label="$t(item.label)" v-model="item.selectedValue" v-if="item.name === PROJECT" @keyup="searchProjects($event.target.value)"
                :loading="projectLoader" :items="listOfProjects" :no-data-text="hasSearchResults ? $t('startTyping') : $t('noData')" item-text="data.name" item-value="_id"
                hide-details="auto">
              </v-autocomplete>
              <v-autocomplete  outlined dense hide-details="auto" :label="$t(item.label)" v-model="item.selectedValue" v-else
                :items="item.listOfValues" item-text="data.name" item-value="_id" @keyup="debouncedModuleRelationListSearch(item.name, index, $event.target.value)">
              </v-autocomplete>
            </v-flex>
          </template>
          <!--end-->
          <v-flex lg12 v-if="$route.query && !!$route.query.convertticket">
            <v-checkbox v-model="ticketObj.deleteparenttask" :label="$t('taskDeleteConfirm')" class="pt-2"></v-checkbox>
          </v-flex>
          <v-flex lg12 v-if="$route.query && !!$route.query.converttask">
            <v-checkbox v-model="ticketObj.deleteparentticket" :label="$t('ticketDeleteConfirm')" class="pt-2"></v-checkbox>
          </v-flex>
          </v-layout>
        </v-container>
        <v-container>
          <template>
            <v-flex lg12>
              <label class="font-weight-bold">{{ $t('attachments') }} :</label>
              <v-col cols="12" class="px-0 pl-0 mt-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn color="info" @click="pickFile" v-on="on" small>
                      <v-icon>mdi-paperclip</v-icon>
                    </v-btn>
                    <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" style="display:hidden"
                      class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file"/>
                  </template>
                  {{ $t('attachFiles') }}
                </v-tooltip>
                <v-tooltip bottom v-if="!ticketObj.is_task">
                  <template v-slot:activator="{ on }">
                    <v-btn color="info" @click="openAttachmentsDialog(2)" class="ml-5" v-on="on" small>
                      <v-icon>mdi-cloud</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('attachFilesFromSharepoint') }}
                </v-tooltip>
                <template>
                  <v-sheet class="mt-2 pa-1 custom_rounded-xxl" color="#F5F7FA" v-if="listOfAttachments && listOfAttachments.length">
                    <v-layout row wrap class="ma-0">
                      <v-flex lg3 md4 sm6 v-for="(files, index) in listOfAttachments" :key="`file_${index}`" class="py-1 pl-3 pr-0">
                        <v-card outlined>
                          <v-layout row wrap class="ma-0 pa-1" align-center>
                            <v-flex lg2 class="text-center  pa-1">
                              <v-sheet color="#DFE3ED" width="40" height="40" :rounded="true" style="display: flex;">
                                <v-icon class="ma-auto" :color="files.color" size="22"> {{ files.icon }} </v-icon>
                              </v-sheet>
                            </v-flex>
                            <v-flex lg8 class="text-truncate" :class="$vuetify.breakpoint.smAndDown ? '' : 'show_divider_border_right'">
                              <p class="subtitle-2 mb-0 text-truncate" :title="files.name">{{ files.name }}</p>
                            </v-flex>
                            <v-flex lg2 class="text-center">
                              <v-icon color="error" @click="removeAttachment(index, 'listOfAttachments')" size="22"> mdi-close-circle-outline </v-icon>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-sheet>
                </template>
              </v-col>
            </v-flex>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6" v-for="(files, index) in listOfOtherFiles" :key="`file_${index}`" class="pb-1">
                <v-card outlined height="50">
                  <v-row class="align-center">
                    <v-col cols="1" lg="1" sm="2" md="2">
                      <v-icon class="mr-4 ml-2" :color="files.color">{{ files.icon }}</v-icon>
                    </v-col>
                    <v-col class="d-flex align-center" cols="9" lg="9" sm="8" md="8">
                      <span class="pl-3 caption">{{ files.name }}</span><br><br>
                    </v-col>
                    <v-col cols="2" lg="2" sm="2" md="2">
                      <v-icon color="error" @click="removeAttachment(index, 'listOfOtherFiles')">mdi-close-circle-outline</v-icon>
                    </v-col>
                  </v-row>
              </v-card>
              </v-col>
            </v-row>
          </template>
          <v-layout wrap>
          <v-flex lg12 xs12 class="mt-5">
            <span v-if="type">{{$t('description')}}</span>
            <span v-else>{{$t('body')}}</span>
            <div class="ticket-editor">
              <ckeditor :editor="editor" v-model="ticketObj.mailbody" ref="editor" :key="render"></ckeditor>
              <!-- <app-editor :editor="editor" v-model="ticketObj.body" :config="editorConfig" ref="editor" :key="render"></app-editor> -->
            </div>
          </v-flex>
          </v-layout>
        </v-container>
      </template>
    </form-template>
    <!-- Attachmets -->
    <v-dialog v-model="attachmentsDialog" persistent width="800" class="mt-0">
      <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`" class="pa-3 pb-2 title">
         {{ $t('attachments') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <sharepoint-tree-view @copyDocuments="getAttachmentFiles" v-if="attachmentsDialog"></sharepoint-tree-view>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="includeFilesForAttachments()">{{ $t('ok') }}</v-btn>
          <v-btn color="error" @click="attachmentsDialog = false">{{ $t('close') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import FormTemplate from '../../components/FormTemplate'
import Editor from '@/ckeditor'
import CommonFunctions from './mixin'
export default {
  mixins: [CommonFunctions],
  data () {
    return {
      editor: Editor,
      MODULE_URL: 'tickets',
      type: this.$route.query.type === 'task' || !!this.$route.query.converttask ? true : false,
      ticketObj: {
        body: `<br><div class="content"><iframe id="userSign" frameborder="0" width="100%"></iframe></div>`
      },
      loading: false,
      loader: false,
      emailRegex: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/,
      listOfAccounts: [],
      isLoading: false,
      accountLoading: false,
      enableSearch: false,
      render: 0,
      modulesRelated: [],
      attachmentsList: [],
      attachmentsFiles: [],
      listOfFiles: [],
      listOfOtherFiles: [],
      imagesTypes: ['image/bmp', 'image/png', 'image/jpg', 'image/jpeg'],
      listOfProjects: [],
      projectLoader: false,
      title: this.$route.query.type === 'task' || !!this.$route.query.converttask ? this.$t('createTask') : this.$t('createTicket'),
      hasSearchResults: true,
      listOfEmailSuggestions: [],
      toEmailSuggestionLoading: false,
      ccEmailSuggestionLoading: false,
      bccEmailSuggestionLoading: false,
      debouncedGetEmailSuggestions: null,
      debouncedModuleRelationListSearch: null,
      filelist: [],
      sharepoint: {} ,
      hideDragFile: false,
      noFileSelect: false,
      attachmentsDialog: false,
      currentItem: null,
      typeOfFileInput: null,
      attachmentsFiles: [],
      listOfFiles: [],
      listOfAttachments: []
    }
  },
  components: {
    FormTemplate,
    'sharepoint-tree-view': () => import('./SharepointTreeView.vue')
  },
  computed: {
    ...mapGetters(['formType', 'getUsers', 'getListOfMailboxes', 'getListOfTicketPriorities', 'getListOfTicketStatus', 'getListOfTicketCategory', 'getsignalRConnection', 'getListOfGroups', 'listOfModules', 'userDetails']),
  //  ...mapGetters(['getListOfTicketPriorities', 'getListOfTicketStatus', 'getListOfTicketType' ,'getUsers', 'getListOfMailboxes', 'getsignalRConnection', 'getuserDetails']),
    formReferences () {
      let item = {
        // title: this.$route.query.type === 'task' || !!this.$route.query.converttask ? this.$t('createTask') : this.$t('createTicket'),
        properties: [{
          model: 'subject',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('subject'),
          class: 'lg4 sm4 xs12'
        }, {
          model: 'to',
          type: this.formType.COMBOBOX,
          multiple: true,
          rules: this.$_multiSelectValidation,
          items: this.listOfEmailSuggestions,
          select_text: 'nameAndEmail',
          select_value: 'emailaddress',
          class: 'lg4 sm4 xs12',
          label: this.$t('to')+'*',
          is_visible: !this.ticketObj.is_task,
          live_search_model: 'to_searchTerm',
          loading: this.toEmailSuggestionLoading,
          keyup: (prop) => this.debouncedGetEmailSuggestions(this.ticketObj[prop.live_search_model], prop)
        }, {
          model: 'cc',
          type: this.formType.COMBOBOX,
          multiple: true,
          items: this.listOfEmailSuggestions,
          select_text: 'nameAndEmail',
          select_value: 'emailaddress',
          class: 'lg2 sm4 xs12',
          label: this.$t('cc'),
          is_visible: !this.ticketObj.is_task,
          live_search_model: 'cc_searchTerm',
          loading: this.ccEmailSuggestionLoading,
          keyup: (prop) => this.debouncedGetEmailSuggestions(this.ticketObj[prop.live_search_model], prop)
        }, {
          model: 'bcc',
          type: this.formType.COMBOBOX,
          multiple: true,
          items: this.listOfEmailSuggestions,
          select_text: 'nameAndEmail',
          select_value: 'emailaddress',
          class: 'lg2 sm4 xs12',
          label: this.$t('bcc'),
          is_visible: !this.ticketObj.is_task,
          live_search_model: 'bcc_searchTerm',
          loading: this.bccEmailSuggestionLoading,
          keyup: (prop) => this.debouncedGetEmailSuggestions(this.ticketObj[prop.live_search_model], prop)
        }, {
          model: 'account_id',
          live_search_model: 'accountSearch',
          type: this.formType.AUTO_COMPLETE_LIVE,
          items: this.listOfAccounts,
          select_text: 'data.name',
          select_value: '_id',
          class: 'lg3 sm4 xs12',
          label: this.$t('account'),
          loading: this.accountLoading,
          change: this.loadValues
        }, {
          model: 'assignedto',
          type: this.formType.AUTO_COMPLETE,
          items: this.getUsersAndGroups,
          rules: this.$_requiredValidation,
          select_text: 'name',
          select_value: 'alternateid',
          class: 'lg2 sm4 xs12',
          label: this.$t('assignedTo')
        }, {
          model: 'mailboxid',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfMailboxes,
          select_text: 'name',
          select_value: '_id',
          class: 'lg2 sm4 xs12',
          rules: this.$_requiredValidation,
          label: this.$t('mailbox')
          // is_visible: !this.type
        }, {
          model: 'status',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfTicketStatus,
          rules: this.$_requiredValidation,
          select_text: 'name',
          select_value: '_id',
          class: 'lg2 sm4 xs12',
          label: this.$t('status')
        }, {
          model: 'priority',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfTicketPriorities,
          select_text: 'name',
          select_value: '_id',
          class: 'lg2 sm4 xs12',
          label: this.$t('priority')
        }, {
          model: 'category_id',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfTicketCategory,
          select_text: 'name',
          select_value: '_id',
          class: 'lg2 sm4 xs12',
          label: this.$t('category')
        } 
        // {
        //   model: 'tags',
        //   type: this.formType.COMBOBOX,
        //   rules: [],
        //   multiple: true,
        //   items: [],
        //   label: this.$t('tags'),
        //   class: 'lg3 sm4 xs12 pt-2'
        // }
        ]
        // buttons: [{
        //   name: 'action_handler',
        //   color: 'success',
        //   label: this.ticketObj.id ? this.$t('update') : this.$t('save'),
        //   click: this.saveUpdateHandler,
        //   loading: this.loader,
        //   is_show: true
        // }, {
        //   name: 'close',
        //   color: 'info',
        //   // eslint-disable-next-line
        //   click: () => this.$router.push(`/${this.MODULE_URL}`),
        //   label: this.$t('backToList'),
        //   is_show: true
        // }]
      }
      return item
    },
    getUsersAndGroups () {
      let userList = this.$formatter.cloneVariable(this.getUsers)
      let groupList = this.$formatter.cloneVariable(this.getListOfGroups)
      return [ ...userList, ...groupList ]
    },
    getAccountSubModules () {
      const accountModule = this.listOfModules.find(x => x.name === this.ACCOUNT)
      const accountSubmodules = accountModule ? (accountModule.submodule || []) : []
      return accountSubmodules
    }
  },
  async mounted () {
    this.debouncedGetEmailSuggestions = this.$formatter.debounce(this.getEmailSuggestions, 500)
    this.debouncedModuleRelationListSearch = this.$formatter.debounce(this.getRelatedList, 500)
    // Load to modules related to tickets
    let modulesRelated = this.listOfModules.filter(x => x.include_ticket && x.isactive && (x.name !== this.ACCOUNT)) // since account is hard coded hide from here
    this.modulesRelated = this.$formatter.cloneVariable(modulesRelated)
    let ticketInfo = JSON.parse(window.localStorage.getItem('taskOrTicketInfo'))
    if (this.$route.query && this.$route.query.type && this.$route.query.type === 'task') {
      this.ticketObj.is_task = true
    } else this.ticketObj.is_task = false
    // To Convert task to ticket / ticket to task
    if (this.$route.query && (!!this.$route.query.converttask || !!this.$route.query.convertticket)) {
      if (this.$route.query.convertticket) {
        ticketInfo.to = []
      }
      this.enableSearch = false
      let query = this.$route.query
      if (ticketInfo) {
        if(this.$route.query.converttask) {
          ticketInfo.body = ticketInfo.mailbody
        if (ticketInfo.mail_info && ticketInfo.mail_info.length > 0) {
          ticketInfo.subject = ticketInfo.mail_info[0].subject
        }
        let ticketObj = {
          ...ticketInfo,
          is_task : query.convertticket ? false : true,
          parenttaskid: query.convertticket ? ticketInfo._id : undefined,
          deleteparenttask: query.convertticket ? false : undefined,
          deleteparentticket: query.converttask ? false : undefined,
          parentticketid: query.converttask ? ticketInfo._id : undefined,
          cc: '',
          bcc: '',
          from: '',
          _id: null,
          mailboxid: this.$route.query.mailboxid ? this.$route.query.mailboxid : null,
         
        }
        this.type = query.convertticket ? 'ticket' : 'task'
        this.ticketObj = Object.assign({}, this.ticketObj, ticketObj)
        this.loadValues() // Load realted fields values filtered by account
        if (ticketInfo.modulerelations && ticketInfo.modulerelations.length > 0)
        this.modulesRelated.forEach(element => { // Set Related module values
          let result = ticketInfo.modulerelations.find(x => x.module === element.name)
          if (result) element.selectedValue = result._id
        })
        this.listOfAccounts = ticketInfo.account_info
        this.render++
        setTimeout(() => { this.enableSearch = true })
          // })
          // .catch(err=>console.log(err))
         } else{
        if (ticketInfo.mail_info && ticketInfo.mail_info.length > 0) {
          ticketInfo.body = `${ticketInfo.mail_info[0].body} ${this.ticketObj.body}`
          ticketInfo.subject = ticketInfo.mail_info[0].subject
        }
        let ticketObj = {
          ...ticketInfo,
          is_task : query.convertticket ? false : true,
          parenttaskid: query.convertticket ? ticketInfo._id : undefined,
          deleteparenttask: query.convertticket ? false : undefined,
          deleteparentticket: query.converttask ? false : undefined,
          parentticketid: query.converttask ? ticketInfo._id : undefined,
          cc: '',
          bcc: '',
          from: '',
          _id: null,
          mailboxid: this.$route.query.mailboxid ? this.$route.query.mailboxid : null
        }
        this.type = query.convertticket ? 'ticket' : 'task'
        this.ticketObj = Object.assign({}, this.ticketObj, ticketObj)
        this.loadValues() // Load realted fields values filtered by account
        if (ticketInfo.modulerelations && ticketInfo.modulerelations.length > 0)
        this.modulesRelated.forEach(element => { // Set Related module values
          let result = ticketInfo.modulerelations.find(x => x.module === element.name)
          if (result) element.selectedValue = result._id
        })
        this.listOfAccounts = ticketInfo.account_info
        this.render++
        setTimeout(() => { this.enableSearch = true })
      }
    }
    }
    // this.$store.dispatch('getListOfGroups')
    this.$store.dispatch('getUsers')
    this.$store.dispatch('getMailbox')
    this.$store.dispatch('getTicketStatus').then(() => {
      this.setDefaultValues({ prop: 'status', list: this.getListOfTicketStatus })
    })
    this.$store.dispatch('getTicketPriority').then(() => {
      this.setDefaultValues({ prop: 'priority', list: this.getListOfTicketPriorities })
    })
    this.$store.dispatch('getTicketCategory').then(() => {
      this.setDefaultValues({ prop: 'category_id', list: this.getListOfTicketCategory })
    })
    let pathHistory = localStorage.getItem('pathHistory') ? JSON.parse(localStorage.getItem('pathHistory')) : null
    /* I have handled it via query param passing
    if (pathHistory) {
      if (pathHistory.module === 'Account' && pathHistory.name) {
        this.listOfAccounts = [{ _id: pathHistory.path.slice(-24), data: { name: pathHistory.name } }]
        this.ticketObj.account_id = pathHistory.path.slice(-24)
        this.ticketObj.accountSearch = pathHistory.name
        this.loadAccountList(pathHistory.name)
        this.loadValues()
        this.modulesRelated.map(x => x.selectedValue = null)
      } else {
        this.modulesRelated.forEach(x => {
          if(x.name == pathHistory.module && pathHistory.name) {
            if (pathHistory.module === this.PROJECT) this.listOfProjects = [{ _id: pathHistory.path.slice(-24), data: { name: pathHistory.name } }]
            else x.listOfValues = [{ _id: pathHistory.path.slice(-24), data: { name: pathHistory.name } }]
            x.selectedValue = pathHistory.path.slice(-24)
          } else x.selectedValue = null
        })
      }
    } */
    if (this.$route.query.type !== 'task' && this.type !== 'task' ) {
      setTimeout(() => {
        let item = ''
        let currentUser = this.getUsers.find(x => x._id === this.userDetails._id)
        if(this.$route.query && (!!this.$route.query.converttask || !!this.$route.query.convertticket)){
          this.ticketObj.mailbody = this.userDetails.systemconfig && this.userDetails.systemconfig.ticketterm ? ticketInfo.mailbody + `<div class="content"><br><span>${this.userDetails.systemconfig.ticketterm}</span>${currentUser && currentUser.signature ? '<iframe id="userSignFooter" frameborder="0" width="100%"></iframe>' : ''}</div>` : ticketInfo.mailbody + `<div class="content"><br>${currentUser && currentUser.signature ? '<iframe id="userSignFooter" frameborder="0" width="100%"></iframe>' : ''}</div>`
        } else {
          this.ticketObj.mailbody = this.userDetails.systemconfig && this.userDetails.systemconfig.ticketterm ? `<div class="content"><br><span>${this.userDetails.systemconfig.ticketterm}</span>${currentUser && currentUser.signature ? '<iframe id="userSignFooter" frameborder="0" width="100%"></iframe>' : ''}</div>` : `<div class="content"><br>${currentUser && currentUser.signature ? '<iframe id="userSignFooter" frameborder="0" width="100%"></iframe>' : ''}</div>`
        }
        if (currentUser && currentUser.signature) {
          item = `${currentUser.signature}`
          const tempDiv = document.createElement('div')
          tempDiv.innerHTML = item
          const userSignDiv = tempDiv.querySelector('.user-sign')
          const imgElement = tempDiv.querySelector('.user-sign img')
          const dynamicHeight = '150px' // Replace with your desired height
          const dynamicWidth = '150px' // Replace with your desired width
          if (userSignDiv) {
            userSignDiv.style.height = dynamicHeight
            userSignDiv.style.width = dynamicWidth
          }
          if (imgElement) {
            imgElement.style.height = '100%'
            imgElement.style.width = '100%'
          }
          let modifiedItem = tempDiv.innerHTML
          item = modifiedItem
          setTimeout(() => {
            document.getElementById('userSignFooter').srcdoc = item
          }, 350)
        }
      }, 150)
    }
    if (this.modulesRelated.length && this.$route.query && this.$route.query.fromModule && this.$route.query) {
      // Set the record in the field by default
      await this.setModuleRecordInTheField(this.$route.query)
    }
    // this.enableSearch = true
  },
  watch: {
    'ticketObj.accountSearch' (val) {
      if (val === undefined) return
      if (val && !val.includes('-') && this.enableSearch) this.loadAccountList(val)
    }
  },
  methods: {
    setDefaultValues (data) {
      let { list, prop } = data
      let item = list.find(x => x.isdefault)
      // let model = {}
      if (item) {
        this.ticketObj[prop] = item._id
        // model = Object.assign({ ...this.ticketObj }, { [prop]: item._id })
        // this.ticketObj = model
      }
    },
    supportingList (type, text) {
      text = this.$refs.ticketReference.$refs[text][0].lazySearch
      let model = { module: type, dialog: true, text }
      this.$store.commit('supportingList', model)
    },
    loadAccountList (searchTerm) {
      this.accountLoading = true
      this.listOfAccounts = []
      const model = {
        searchterm: searchTerm || ''
      }
      this.$api.execute('get', `moduledata/${this.ACCOUNT}/live_search_for_dropdown?searchterm=${model.searchterm || ''}&relation_module=${""}&relation_id=${""}`).then((response) => {
        this.listOfAccounts = response.data
      }).finally(() => {
        this.accountLoading = false
      })
    },
    async saveAttachmentsHandler () {
      var formData = new FormData()
      var filesList = this.attachmentsFiles
      if (filesList !== null && filesList.length > 0) {
        filesList.forEach(file => {
          formData.append(file.name, file)
        })
      }
      return new  Promise((resolve, reject) => {
        this.$api.execute('post', 'mailattachments/upload', formData)
          .then(({ data }) => {
            if (data && data.length) {
              let attachmentIds = data.map(x => x._id)
              resolve(attachmentIds)
            }
          })
      })
    },
    async saveUpdateHandler () {
      if (this.$refs.ticketReference.$refs.validateForm.validate()) {
        let ticketObj = this.$formatter.cloneVariable(this.ticketObj)
        ticketObj.body = ticketObj.body.replace('<div class="content"><iframe width="100%" frameborder="0" id="userSignFooter"> </iframe></div>', '')
        let to = this.ticketObj.to ? ticketObj.to : []
        let cc = ticketObj.cc ? ticketObj.cc : []
        let bcc = this.ticketObj.bcc ? ticketObj.bcc : []
        if (!this.type) {
          let arrayOfEmails = [...to, ...cc, ...bcc]
          if (arrayOfEmails.length > 0) {
            for (let email of arrayOfEmails) {
              if (!this.emailRegex.test(email)) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'invalidEmail' })
                return false
              }
            }
          }
        }
        // console.log(this.$route.query.converttask);
        if (!this.$route.query.converttask){
          let formattedto = to.map(email => ({
            emailaddress: {
              address: email,
              name: ''
            }
          }));
          ticketObj.toRecipients = formattedto
          ticketObj.body = ticketObj.mailbody
          if(this.$route.query.convertticket){
            ticketObj.body = ticketObj.mailbody
          }
          if(this.$route.query.type){
            ticketObj.body = ticketObj.mailbody
          }
        } else{
          ticketObj.body = ticketObj.mailbody
          ticketObj.to = []
          ticketObj.mailboxid = this.$route.query.mailboxid
          ticketObj.outlookconversationid = this.$route.query.conversationid
        }
        let formattedcc = cc.map(email => ({
          emailaddress: {
            address: email,
            name: ''
          }
        }))
        ticketObj.ccRecipients = formattedcc
        let formattedbcc = bcc.map(email => ({
          emailaddress: {
            address: email,
            name: ''
          }
        }))
        ticketObj.bccRecipients = formattedbcc
        this.loader = true
        let url = `${this.MODULE_URL}/create_ticket_through_graphapi`
        let model = ticketObj
        model.tags = JSON.stringify(model.tags)
        let assignedTo = []
        if (model.assignedto) {
          assignedTo = model.assignedto.split('_')
          if (assignedTo[0] === 'user') {
            model.assignedto = assignedTo[1]
            model.assignedto_group =  null
          } else {
            model.assignedto = null
            model.assignedto_group =  assignedTo[1]
          }
        }
        let modelRelatedModule = this.$formatter.cloneVariable(this.modulesRelated)
        model.modulerelations = []
        // If any module as ticket relation
        if (modelRelatedModule.length > 0) {
          model.modulerelations = modelRelatedModule.map(x => { return { id: x.selectedValue, module: x.name} }).filter(x => x.id)
        }
        var formData = new FormData()
        if (this.attachmentsFiles && this.attachmentsFiles.length){
          var filesList = this.attachmentsFiles
          if (filesList !== null && filesList.length) {
            filesList.forEach(file => {
              if (!file.is_sharepoint) {
                formData.append(file.name, file)
              } else {
                formData.append(file.id, file)
              }
              // formData.append(file.name, file)
            })
          }
        }
        if(this.ticketObj.is_task) {
          this.$api.execute('post', 'mailattachments/upload', formData).then(response => {
            if (response.data && response.data.length > 0) {
              let attachmentIds = response.data.map(x => x._id)
              // resolve(attachmentIds)
              this.$set(model, 'attachments', attachmentIds)
            }
          })
        }else {
          model.attachments = []
        }
        setTimeout(()=>{
          formData.append('model', JSON.stringify(model))
          this.$api.saveUpdateHandler(url, formData).then(response => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'addedSucess' })
            // console.log(response);
            // return false
            // this.$router.push(`/${this.MODULE_URL}/actions/${response._id}`)
            this.$router.push({path:`/${this.MODULE_URL}/actions/${response._id}`,query: { mailboxid: response.mailboxid, conversationid: response.outlookconversationid }})
            window.localStorage.removeItem('taskOrTicketInfo')
            if (model.assignedTo && this.userDetails._id != model.assignedto) {
              let  msg = ''
              if (assignedTo[0] === 'user') {
                msg = `${this.$t('assignedtoMessage')} ${response.number} ${this.$t('by')} ${this.userDetails.name}`
                this.getsignalRConnection.invoke('SendNotification', [assignedTo[1]], msg , response._id)
              } else {
                // let item  this.getListOfGroups.find(x => x.id == assignedTo[1])
                // let usersarray = []
                // let query = { filter: `groupid eq ${assignedTo[1]} and isactive eq 1` }
                // msg = `${item.name} ${this.$t('assignedFor')} ${response.number} ${this.$t('by')} ${this.userDetails.name}`
                // this.$api.execute('get', `usergroups/get_by_group/${assignedTo[1]}`).then(result => {
                //   usersarray = result.data.map(x => x.user_id)
                // }).finally(() => {
                //   this.getsignalRConnection.invoke('SendNotification', usersarray, msg, response._id)
                // })
              }
            }
          }).finally(() => {
            this.loader = false
          })
        },1200)
      }
    },
    saveAirwayBill (id, model) {
       let airwayBills = {
          ids: model
        }
      this.$api.saveUpdateHandler(`airwaybills/update_bills/${id}`, airwayBills)
    },
    async onFilePicked (fileslist) {
      const files = fileslist
      for (let file of files) {
        if (file) {
          this.attachmentsFiles.push(file)
          let iconObj = this.getMimeTypeIcons(file.type)
          this.listOfOtherFiles.push({ name: file.name, type: file.type, ...iconObj })
          // item.listOfOtherFiles.push({ name: files[i].name, type: files[i].type, ...iconObj })
        }
      }
    },
    pickFile () {
      this.typeOfFileInput = 1
      this.$refs.file.click()
    },
    loadValues () {
      this.modulesRelated.forEach((element, index) => {
        this.modulesRelated[index].listOfValues = []
        if (element.name === this.PROJECT) this.getProjectById(this.ticketObj)
        else if (this.ticketObj.account_id && this.getAccountSubModules.includes(element.name)) {
          element.selectedValue = ''
          this.getRelatedList(element.name, index)
        }
      })
    },
    async getRelatedList (name, index, searchTerm = '') {
      console.log('in')
      let url = `moduledata/${name}/live_search_for_dropdown?searchterm=${searchTerm || ''}`
      if (this.getAccountSubModules.includes(name)) url += `&relation_module=${this.ACCOUNT}&relation_id=${this.ticketObj.account_id}`
      await this.$api.execute('get', url).then(({ data: result }) => {
         // if (name === this.CONTACT || name === this.PROJECT) {}
        result.forEach(item => {
          item.data.name = name === this.CONTACT ? `${item.data.name || ''} ${item.data.last_name || ''}` : `${item.data.number || ''} ${item.data.name || ''}`
        })
        this.$set(this.modulesRelated, index, { ...this.modulesRelated[index], listOfValues: result })
      })
    },
    removeAttachment (index, listname) {
      this[listname].splice(index, 1)
    },
    getEmailSuggestions (searchTerm = '', prop) {
      this[`${prop.model}EmailSuggestionLoading`] = true
      this.$api.execute('get', `emailaccounts/get_email_suggesstion?searchTerm=${searchTerm || ''}`).then(({ data: emailSuggestions}) => {
        const suggestedEmails = emailSuggestions.map(x => {
          x.nameAndEmail = [x.name, (x.name ? `(${x.emailaddress})` : x.emailaddress)].filter(x => x).join(' ')
          return x
        })
        this.listOfEmailSuggestions = []
        this.listOfEmailSuggestions = suggestedEmails
      }).finally(() => {
        this[`${prop.model}EmailSuggestionLoading`] = false
      })
    },
    onChange () {
      this.filelist = [...this.$refs.file.files]
      this.includeFilesForAttachments()
    },
    remove (i) {
      this.filelist.splice(i, 1)
    },
    openDocument (url) {
      window.open(url, '_blank')
    },
    openAttachmentsDialog (type) {
      this.typeOfFileInput = type
      this.attachmentsDialog = true
    },
    includeFilesForAttachments () {
      if (this.typeOfFileInput === 1) {
        this.onFilePicked(this.filelist)
        // this.attachmentsDialog = false
        this.closeAttachmentsDialog()
      } else this.$eventBus.$emit('copySelectedDocuments')
    },
    getAttachmentFiles (files) {
      const listOfFiles = [...files]
      this.onFilePicked(listOfFiles)
      this.attachmentsDialog = false
    },
    closeAttachmentsDialog () {
      this.filelist = []
      this.attachmentsDialog = false
    },
    async setModuleRecordInTheField ({ fromModule, recordId }) {
      const response = await this.$api.execute('get', `moduledata/${fromModule}/get_by_id/${recordId}`)
      if (response && response.data) {
        const relatedModuleObjIndex = this.modulesRelated.findIndex(x => x.name === fromModule)
        const moduleRecords = [response.data]
        moduleRecords.forEach(item => {
          item.data.name = fromModule === this.CONTACT ? `${item.data.name || ''} ${item.data.last_name || ''}` : `${item.data.number || ''} ${item.data.name || ''}`
        })
        this.modulesRelated[relatedModuleObjIndex].selectedValue = recordId
        if (fromModule === this.PROJECT) this.listOfProjects = moduleRecords
        else {
          this.modulesRelated[relatedModuleObjIndex].listOfValues = moduleRecords
          this.$set(this.modulesRelated, relatedModuleObjIndex, this.modulesRelated[relatedModuleObjIndex])
        }
      }
    }
  }
}
</script>
<style>
.combo .v-text-field .v-input__append-inner {
  display: none!important;
}
.ck-editor__editable_inline {
    min-height: 100px;
}
.ck-focused, .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border-color: #c4c4c4!important;
   box-shadow: 0 0 0px 1px #6cb5f9!important;
   border: #c4c4c4 !important;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #c4c4c4 !important;
}
[v-cloak] {
  display: none;
}
.p-12 {
  padding: 1.5rem;
}
.border {
  border-width: 1px;
}
.border-gray-300 {
  border-color: #e2e8f0;
}
.bg-gray-100 {
  background-color: #e1e1e1;
  border: 2px dashed #036693b5;
}
.w-px {
  width: 1px;
}
.absolute {
  position: absolute;
}
.overflow-hidden {
  overflow: hidden;
}
.opacity-0 {
  opacity: 0;
}
.h-px {
  height: 1px;
}
.block {
  display: block;
}
.underline {
  text-decoration: underline;
}
.text-sm {
  font-size: .875rem;
}
.p-1 {
  padding: 0.25rem;
}
.width-100 {
  width: 100%;
  text-align: center;
}

.custom_rounded-xxl {
  border-radius: 8px;
}
.show_divider_border_right {
  border-right: 1px solid rgba(0, 0, 0, 0.085);
}
</style>
